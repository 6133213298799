import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, Tabs, InfoIcon } from '../components';
import { getEntityDefinitionsCurl, getEntityDefinitionsJava, getEntityDefinitionsJavascript, getEntityDefinitionsNode, getEntityDefinitionsPerl, getEntityDefinitionsPython, getEntityDefinitionsPhp, getEntityDefinitionsRuby, getEntityDefinitionsGo, getEntityDefinitionsC, getEntityDefinitionsVB, getEntityDefinitionsGroovy, getEntityDefinitionsObjectiveC, getEntityDefinitionsSwift } from 'requestExamples/entities';
export const baseUrlGet = [{
  param: 'GET',
  value: '/nodes/entity-definition/{path}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  baseUrlGet,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "entity-definitions"
    }}>{`Entity definitions`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			To retrieve metadata related to an entity or entity relationship we can use a{' '}
			<span style={{
            "color": "#3c76e9"
          }}>GET</span> request to /nodes/entity-definition.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			You can avoid specifying the "path" param and use the "?children=x" query param to get the entity structure.
		</p>
	</div>
	<Table title="Retrieve entity definition" rows={baseUrlGet} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string (optional)',
      description: 'The entity path.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getEntityDefinitionsCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getEntityDefinitionsJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getEntityDefinitionsJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getEntityDefinitionsNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getEntityDefinitionsPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getEntityDefinitionsPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getEntityDefinitionsPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getEntityDefinitionsRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getEntityDefinitionsGo
    }, {
      tabTitle: 'C#',
      tabInfo: getEntityDefinitionsC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getEntityDefinitionsVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getEntityDefinitionsGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getEntityDefinitionsObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getEntityDefinitionsSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a default `}<a parentName="p" {...{
        "href": "/api/terminology#node-response"
      }}>{`node response`}</a>{` if the path specified is correct.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
  "error": {
    "success": true
  },
  "message": {
    "name": "MyAccount",
    "uri": "/nodes/entity-definition/MyAccount",
    "your_permissions": 64,
    "total_children": 2,
    "children": [
      {
        "name": "SmartVault.Core.OrganizationalUnit",
        "uri": "/nodes/entity-definition/MyAccount/SmartVault.Core.OrganizationalUnit",
        "your_permissions": 64,
        "total_children": 0
      },
      {
        "name": "SmartVault.Accounting.Firm",
        "uri": "/nodes/entity-definition/MyAccount/SmartVault.Accounting.Firm",
        "your_permissions": 64,
        "total_children": 4,
        "children": [
          {
            "name": "SmartVault.Accounting.FirmToInternalDocuments",
            "uri": "/nodes/entity-definition/MyAccount/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmToInternalDocuments",
            "your_permissions": 64
          },
          {
            "name": "SmartVault.Accounting.FirmToEmployee",
            "uri": "/nodes/entity-definition/MyAccount/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmToEmployee",
            "your_permissions": 64
          },
          {
            "name": "SmartVault.Accounting.FirmClient",
            "uri": "/nodes/entity-definition/MyAccount/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmClient",
            "your_permissions": 64
          },
          {
            "name": "SmartVault.Accounting.FirmToPublicDocuments",
            "uri": "/nodes/entity-definition/MyAccount/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmToPublicDocuments",
            "your_permissions": 64
          }
        ]
      }
    ]
  }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the path specified doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "693476f2-8971-450d-a7b6-287cc986f3df",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      